<template>
    <div>
        <el-upload
            ref="upload"
            class="mx-2"
            :list-type="'text'"
            :show-file-list="false"
            :on-change="handleChange"
            :auto-upload="false"
            drag
            action=""
            :on-exceed="imageOverLimit"
            accept=".jpg,.jpeg,.png,.JPG,.JPEG">
            <div slot="default" class="relative">
                <!-- 呈現字串得上傳框 -->
                <div class="w-32 h-32 border-dashed border-2 rounded-lg flex items-center justify-center text-red-500 border-red-500">
                    <i class="el-icon-plus mr-1 text-red-500" style="font-size: 20px"></i>其它照片
                </div>
            </div>
        </el-upload>

        <!-- 裁切時使用彈窗方式 -->
        <el-dialog
            :visible.sync="showDialog"
            :close-on-click-modal="true"
            :modal-append-to-body="true"
            :close-on-press-escape="true"
            @close="closeDialog">
            <div v-if="cropped === null">
                <vue-croppie
                    ref="croppieRef"
                    :enableOrientation="true"
                    :enableResize="false"
                    :boundary="{ width: 350, height: 350 }"
                    :viewport="{ width: 330, height: 330 }"
                    @result="result">
                </vue-croppie>
                <div class="text-center">
                    <button class="yellow-btn-500 sm:text-xl text-base mr-10" @click="cropViaEvent()">確定</button>
                    <button class="red-btn-outline-600 sm:text-xl text-base" @click="handleRemove">取消</button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            // 裁切圖 base64
            cropped: null,
            // 選取到的圖片檔
            photoFile: null,
            // 判斷是否顯示彈窗
            showDialog: false,
        };
    },
    methods: {
        //圖片變更
        handleChange(file) {
            if (file.size > 5 * 1024 * 1024) {
                this.$message({
                    type: "error",
                    message: "圖片尺寸過大",
                });
                return;
            }
            // 判斷是否使用彈窗來做裁切圖片
            this.showDialog = true;
            // 圖片資料
            this.photoFile = null;
            // 裁切後圖片 base64 資料
            this.cropped = null;
            // 設定圖片為上傳檔案
            this.photoFile = file.raw;
            // 讀取 file 檔案
            const reader = new FileReader();
            // 設定讀取 file 檔案
            reader.readAsDataURL(this.photoFile);
            // 將檔案 轉換成 base64
            reader.onload = () => {
                // 裁切套件設定裁切圖片函示
                this.$refs.croppieRef.bind({
                    url: reader.result,
                });
            };
        },
        //移除圖片
        handleRemove() {
            this.photoFile = null;
            this.cropped = null;
            // 關閉彈窗
            this.showDialog = false;
        },
        // 關閉彈窗
        closeDialog() {
            // 關閉彈窗
            this.showDialog = false;
        },
        //上傳圖片超過限制數量
        imageOverLimit() {
            this.showDialog = false;
            this.$message({
                type: "error",
                message: "超過上傳圖片上限",
            });
        },
        // 裁切事件
        cropViaEvent() {
            const options = {
                type: "blob",
                format: "jpeg",
                circle: false,
                size: { width: 1080, height: 1080 },
            };
            this.$refs.croppieRef.result(options);
            // 關閉彈窗
            this.showDialog = false;
        },
        /**
         * 裁切完後 結果
         * @param { type Blob(blob檔案格式)) } blob 檔案資料
         */
        result(blob) {
            // 讀取檔案資料
            const file = new FileReader();
            // 將blob檔案轉譯成 base 64
            file.readAsDataURL(blob);
            file.onload = () => {
                // 將base64 url 賦予 此變數
                this.cropped = file.result;
                // 裁切成功時要回傳一個事件
                this.$emit("onCropped", blob, this.cropped);
            };
            // 儲存裁切後檔案
            this.photoFile = blob;
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep .el-upload-dragger {
    @apply flex items-center justify-center border-none;
}
::v-deep .el-upload-list {
    @apply sr-only;
}
::v-deep .el-upload-dragger {
    @apply h-auto w-full;
}
::v-deep .el-upload {
    @apply w-auto;
}
::v-deep .el-dialog {
    @screen sm {
        @apply w-[500px];
    }
    @apply w-auto;
}
</style>

import Vue from "vue";
// 導入 emojiRegex 套件
import emojiRegex from "emoji-regex";
/**
 * CheckEmailUniqueApi : 判斷信箱是否重複 api
 */
import { CheckEmailUniqueApi } from "@/api/cms/utilsApi";
/**
 * 信箱驗證
 * @param { type String(字串) } value 表單值
 */
export const formEmailCheckAlready = async (value) => {
    // 判斷信箱是否為空值 或 信箱格式是否正確
    if (
        value !== "" &&
        // eslint-disable-next-line no-useless-escape
        /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/.test(value)
    ) {
        try {
            const { status } = await CheckEmailUniqueApi(value);
            if (Vue.prototype.$successHttpCode.indexOf(status) !== -1) {
                return { success: true };
            } else {
                return { success: false, message: "此電子郵件已有人使用" };
            }
        } catch (err) {
            if (err.response.status === 422) {
                return { success: false, message: "此電子郵件已有人使用" };
            } else {
                return { success: false, message: "信箱驗證錯誤" };
            }
        }
    } else {
        return { success: false, message: "錯誤的電子郵件格式" };
    }
};
/**
 * 驗證密碼是否輸入正確
 * @param { type String(字串) } value 表單值
 * @param { type Strinr(字串) } password 第一次輸入密碼值
 */
export const formCheckPassRule = async (value, password) => {
    if (value === "") {
        return { success: false, message: "驗證密碼為必填" };
    }
    if (value !== password) {
        return { success: false, message: "驗證密碼與密碼不符合" };
    }
    return { success: true };
};
/**
 * 判斷是否18歲
 * @param { type String(字串) } value 表單值
 */
export const formCheckUserAge = async (value) => {
    if (value === "") {
        return { success: false, message: "生日為必填" };
    } else {
        const age = Vue.prototype.$moment().diff(value, "years", true);
        if (age >= 18) {
            return { success: true };
        } else {
            return { success: false, message: "須年滿 18 歲才可以註冊!" };
        }
    }
};
/**
 * 驗證暱稱是否有超過 10個字 以及 不可輸入 emoji 符號
 * @param { type String(字串) } value 表單值
 */

export const formNickNameCheck = async (value) => {
    const regex = emojiRegex();
    // regex.test(value);
    if (value.length > 10) {
        return { success: false, message: "暱稱不得超過 10 個字" };
    }
    if (regex.test(value)) {
        return { success: false, message: "請勿輸入emoji表情符號" };
    }
    return { success: true };
};

/**
 * 判斷年齡不可大於 99且 不得小於 18
 * @param {type Number(數字)} value 年齡
 * @param { type Boolean(布林) } isProvider 判斷是否為服務商
 * @returns
 */
export const formCheckAgeRange = async (value, isProvider = false) => {
    const ageLimit = isProvider ? 20 : 18;
    if (value < ageLimit || value > 99) {
        return {
            success: false,
            message: `年齡不得超過99且不得小於${ageLimit}`,
        };
    }
    return { success: true };
};

/**
 * 判斷數字不可大於 999
 * @param {type Number or String(數字或字串)} value 輸入值
 */
export const formNumberRange = async (value) => {
    if (parseInt(value) > 999) {
        return { success: false, message: `最高999` };
    }
    return { success: true };
};

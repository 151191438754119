export default {
    filters: {
        // 顯示要前往實名驗證的字串
        statusText(val) {
            switch (val) {
                case 0:
                    return "未完成實名驗證";
                case 1:
                    return "完成第一階段實名驗證";
                case 2:
                    return "完成完整實名驗證";
                case -1:
                    return "停權";
                case -2:
                    return "永久停權";
            }
        },
    },
    data() {
        return {
            // 驗證等級
            authLevel: 0,
        };
    },
};

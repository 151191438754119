// 日期格式套件
import moment from "moment";

/**
 * 判斷是否小於 18 歲日期 disabled 跟 未來時間 disabled 以及預設日期為 18歲當年月份
 */

export default {
    data() {
        return {
            // 日期套件過濾
            pickerOptions: {
                disabledDate: (time) => {
                    const year = Number(moment().format("YYYY"));
                    const month = Number(moment().format("MM"));
                    const day = Number(moment().format("DD"));
                    // 判斷時間大於當下時間時 為 disabled 狀態 以及小於18歲日期
                    return time.getTime() > moment() || time.getTime() > new Date(year - 18, month - 1, day);
                },
            },
        };
    },
    methods: {
        // 預設起始時間
        defaultValue() {
            const year = Number(moment().format("YYYY"));
            const month = Number(moment().format("MM"));
            const day = Number(moment().format("DD"));
            return new Date(`${year - 18}-${month}-${day}`);
        },
    },
};

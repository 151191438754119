<template>
    <div>
        <el-upload
            v-if="
                (photoFile === null && !useUploadString) ||
                (cropped !== null && !useUploadString) ||
                (useUploadString && photoFile === null && cropped === null)
            "
            ref="upload"
            :list-type="'text'"
            :on-change="handleChange"
            :on-remove="handleRemove"
            :auto-upload="false"
            :file-list="fileList"
            :limit="1"
            drag
            action=""
            :on-exceed="imageOverLimit"
            accept=".jpg,.jpeg,.png,.JPG,.JPEG">
            <div class="relative">
                <!-- 呈現字串得上傳框 -->
                <div
                    v-if="useUploadString && cropped === null"
                    class="w-32 h-32 border-dashed border-2 rounded-lg flex items-center justify-center text-red-500 border-red-500">
                    <i class="el-icon-plus mr-1" style="font-size: 20px"></i>封面照片
                </div>
                <!-- 呈現預設圖的上傳框 -->
                <img v-if="cropped === null && !useUploadString" class="rounded-full w-52" :src="defaultImg" alt="" />
                <!-- 相機icon -->
                <div
                    v-if="showCameraIcon"
                    class="bg-black h-10 w-10 rounded-full p-2 flex items-center justify-center absolute bottom-1 right-8 z-40">
                    <img class="w-5" src="/img/icons/web/camera.svg" alt="" />
                </div>
                <!-- 上傳後呈現圓形圖效果 -->
                <div v-if="cropped !== null && useCircleImg" class="bg-white rounded-lg">
                    <img class="mx-auto block rounded-full w-52" :src="cropped" />
                </div>
            </div>
            <div v-if="showTip" slot="tip" class="el-upload__tip text-gray-300 text-center">只能上傳jpg/png文件，且不超過5MB</div>
        </el-upload>
        <!-- 封面照上傳顯示方式 -->
        <div v-if="cropped !== null && !useCircleImg" class="relative">
            <div
                class="cursor-pointer bg-opacity-50 bg-black w-6 h-6 flex items-center justify-center text-white absolute z-20 -top-2 -right-2 md:text-sm rounded-full text-xs"
                @click="handleRemoveChaeck">
                <i class="icofont-ui-close text-[10px]"></i>
            </div>
            <img class="mx-auto block w-32 rounded-lg" :src="cropped" />
            <div class="bg-opacity-50 bg-black w-full py-2 text-center text-white absolute bottom-0 md:text-sm rounded-bl-lg rounded-br-lg text-xs">
                封面照片
            </div>
        </div>
        <!-- 裁切時使用彈窗方式 -->
        <el-dialog
            v-show="useDialog"
            :visible.sync="showDialog"
            :close-on-click-modal="true"
            :modal-append-to-body="true"
            :close-on-press-escape="true"
            @close="closeDialog">
            <div v-if="cropped === null">
                <vue-croppie
                    ref="croppieRef"
                    :enableOrientation="true"
                    :enableResize="false"
                    :boundary="{ width: 350, height: 350 }"
                    :viewport="{ width: 330, height: 330 }"
                    @result="result">
                </vue-croppie>
                <div class="text-center">
                    <button class="yellow-btn-500 sm:text-xl text-base mr-10" @click="cropViaEvent()">確定</button>
                    <button class="red-btn-outline-600 sm:text-xl text-base" @click="handleRemove">取消</button>
                </div>
            </div>
        </el-dialog>
        <!-- 直接顯示裁切框方式 -->
        <div v-show="photoFile !== null && cropped === null && !useDialog">
            <vue-croppie
                ref="croppieRef"
                :enableOrientation="true"
                :enableResize="false"
                :boundary="{ width: 350, height: 350 }"
                :viewport="{ width: 330, height: 330 }"
                @result="result">
            </vue-croppie>
            <div class="text-center">
                <button class="yellow-btn-500 sm:text-xl text-base mr-10" @click="cropViaEvent()">確定</button>
                <button class="red-btn-outline-600 sm:text-xl text-base" @click="handleRemove">取消</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        // 預設圖片
        defaultImg: {
            type: String,
            default: "/img/avatar/avatar-icon.svg",
        },
        // 判斷是否使用彈窗
        useDialog: {
            type: Boolean,
            default: false,
        },
        // 判斷是否顯示檔案上傳限制提示文字
        showTip: {
            type: Boolean,
            default: true,
        },
        // 預設上傳檔案時 是否啟用字串方式做提示
        useUploadString: {
            type: Boolean,
            default: false,
        },
        // 判斷是否顯示 相機 icon
        showCameraIcon: {
            type: Boolean,
            default: true,
        },
        // 判斷是否顯示圓形圖示
        useCircleImg: {
            type: Boolean,
            default: true,
        },
        // 封面照
        coverPhoto: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            // 裁切圖 base64
            cropped: null,
            // 上傳檔案列表
            fileList: [],
            // 選取到的圖片檔
            photoFile: null,
            // 打開彈窗
            showDialog: false,
        };
    },
    methods: {
        //圖片變更
        handleChange(file) {
            if (file.size > 5 * 1024 * 1024) {
                this.fileList = [];
                this.$message({
                    type: "error",
                    message: "圖片尺寸過大",
                });
                return;
            }
            // 判斷是否使用彈窗來做裁切圖片
            if (this.useDialog) {
                this.showDialog = true;
            }
            // 圖片資料
            this.photoFile = null;
            // 檔案列表
            this.fileList = [];
            // 裁切後圖片 base64 資料
            this.cropped = null;
            // 設定圖片為上傳檔案
            this.photoFile = file.raw;
            // 新增一個讀取上傳檔案方法
            const reader = new FileReader();
            // 讀取上傳檔案
            reader.readAsDataURL(this.photoFile);
            // 將上傳檔案轉為base64
            reader.onload = () => {
                // 裁切套件設定裁切圖片函示
                this.$refs.croppieRef.bind({
                    url: reader.result,
                });
            };
        },
        //移除圖片
        handleRemove() {
            this.photoFile = null;
            this.fileList = [];
            this.cropped = null;
            // 關閉彈窗
            this.showDialog = false;
        },
        // 顯示確認是否刪除的警告
        handleRemoveChaeck() {
            this.$emit("onRemovePhoto");
        },
        // 關閉彈窗
        closeDialog() {
            this.photoFile = null;
            this.fileList = [];
            this.cropped = null;

            // 關閉彈窗
            this.showDialog = false;
        },
        //上傳圖片超過限制數量
        imageOverLimit() {
            this.showDialog = false;
            this.$message({
                type: "error",
                message: "超過上傳圖片上限",
            });
        },
        // 裁切事件
        cropViaEvent() {
            const options = {
                type: "blob",
                format: "jpeg",
                circle: false,
                size: { width: 1080, height: 1080 },
            };
            this.$refs.croppieRef.result(options);
            // 關閉彈窗
            this.showDialog = false;
        },
        /**
         * 裁切完後 結果
         * @param { type Blob(blob檔案格式)) } blob 檔案資料
         */
        result(blob) {
            // 讀取檔案資料
            const file = new FileReader();
            // 將blob檔案轉譯成 base 64
            file.readAsDataURL(blob);
            file.onload = () => {
                // 將base64 url 賦予 此變數
                this.cropped = file.result;
                // 裁切成功時要回傳一個事件
                this.$emit("onCropped", blob, this.cropped);
            };
            // 儲存裁切後檔案
            this.photoFile = blob;
        },
        // 設定裁切後圖片為後端上傳給的網址
        setCropped(val) {
            this.cropped = val;
        },
        // 自行上傳檔案時需要呼叫方法
        // croppie(e) {
        //     conosle.log(e);
        //     var files = e.target.files || e.dataTransfer.files;
        //     if (!files.length) return;
        //     var reader = new FileReader();
        //     reader.onload = (e) => {
        //         this.$refs.croppieRef.bind({
        //             url: e.target.result
        //         });
        //     };
        //     reader.readAsDataURL(files[0]);
        // }
    },
    watch: {
        coverPhoto(val) {
            this.cropped = val;
        },
    },
    created() {
        // 判斷是否有傳送封面照資料
        if (this.coverPhoto !== null) {
            this.cropped = this.coverPhoto;
        }
    },
};
</script>

<style lang="scss" scoped>
::v-deep .el-upload-dragger {
    @apply flex items-center justify-center border-none;
}
::v-deep .el-upload-list {
    @apply sr-only;
}
::v-deep .el-upload-dragger {
    @apply h-auto w-full overflow-visible;
}
::v-deep .el-upload {
    @apply w-full;
}
::v-deep .el-dialog {
    @screen sm {
        @apply w-[500px];
    }
    @apply w-auto;
}
</style>
